@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Mazzard;
  src: url(../assets/fonts/MazzardM-ExtraBold.otf);
}

@font-face {
  font-family: MazzardLight;
  src: url(../assets/fonts/MazzardM-Bold.otf);
}

@font-face {
  font-family: Nexa;
  src: url(../assets/fonts/NexaBook.otf);
}

.rs-font-mazzard {
  font-family: Mazzard, Arial, Helvetica, sans-serif !important;
}

.rs-font-mazzard--light {
  font-family: MazzardLight, Arial, Helvetica, sans-serif !important;
}

body {
  font-family: Nexa, Arial, Helvetica, sans-serif !important;
}

.rs-text-primary {
  color: #0c3e83 !important;
}

.rs-gradient-primary {
  background-image: linear-gradient(to right bottom, #0c3e83, #0a4589, #094c8f, #0a5495, #0d5b9b);
}

.rs-gradient-primary-transparent {
  background-image: linear-gradient(to right bottom, #0c3e83cc, #0a4589cc, #094c8fcc, #0a5495cc, #0d5b9bcc);
}

.rs-background {
  background-image: url('../assets/img/wave-02.svg');
}

.rs-hero {
  @apply h-screen;
}

.rs-hero-video {
  z-index: -1;
  height: inherit;
}

@media screen(lg) {
  .rs-hero {
    height: calc(100vh + 10rem)
  }
}